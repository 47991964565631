<template>
    <div>
        <Tabelas/>
    </div>
  
</template>

<script>
export default {

    components:{

      // IMPORTO COMPONENTES AQUI NAO PRECISA DECLARAR EM CIMA.. E NAO GERA ERRO DO URL e so chamo apos logado
      Tabelas:     () => import("./Tabelas.vue"),
      // https://stackoverflow.com/questions/55581345/dynamic-importing-components-based-on-route
    },

}
</script>

<style>

</style>